// import { Link } from "gatsby"
import ReactMarkdown from 'react-markdown';
import React from "react"
import Button from "../buttons/button"
import "./CookieBanner.css"

const CookieBanner = (props) => {

  return <div className="gs-cookie-banner text-small">
          <header className="gs-cookie-banner__in color-bg">
          <ReactMarkdown source={props.translation.privacy.banner} className="text mb2"/>
            {/* <p className="mb2">
            We use cookies on our website to see where our traffic comes from. For more information, refer to our <Link to="/privacy" className="link">Privacy policy</Link>.
            </p> */}
            <div className="gs-cookie-banner-actions">
            <Button type={1} className="mr1 mb05" onClick={props.onDecline}>{props.translation.buttons.decline}</Button>
            <Button type={2} className="" onClick={props.onAllow}>{props.translation.buttons.allow}</Button>

            </div>
          </header>
        </div>

}

export default CookieBanner
