import React, { useState, useEffect } from "react"
import CookieBanner from "./CookieBanner";
import { GTAG_OPTIN_KEY } from "gatsby-plugin-google-gtag-optin" // Or use your own if you changed it in the config

// import Cookies from "js-cookie";
import "./CookieConsent.css"
// import { useLocation } from "@reach/router" // this helps tracking the location

const CookieConsent = (props) => {
  /**
   * Default name of the cookie which is set by CookieConsent
   */
  // const defaultCookieConsentName = "gatsby-plugin-google-analytics-gdpr_cookies-enabled";

  const [showBanner, setShowBanner] = useState(false);

  // const location = useLocation();
  
  useEffect(() => {
    const { debug } = props;
    // let CookieConsentGranted = Cookies.get("CookieConsentGranted");
    // console.warn(CookieConsentGranted)
    // if cookie undefined or debug
    if (localStorage.getItem(GTAG_OPTIN_KEY) === null || debug) {
      setShowBanner(true);
    }

  }, [props]);

  function onAllow(){
    // setCookie(defaultCookieConsentName, true)
    // setCookie("CookieConsentGranted", true)
    localStorage.setItem(GTAG_OPTIN_KEY, true)
    if (typeof window.loadGtag == "function") {
      window.loadGtag()
    }
    setShowBanner(false);
    // console.warn("Cookie consent granted");
  }

  function onDecline(){
    // setCookie(defaultCookieConsentName, false)
    // setCookie("CookieConsentGranted", false)
    localStorage.setItem(GTAG_OPTIN_KEY, false);
    setShowBanner(false);
    // console.warn("Cookies declined");
  }

  return <section className="gs-cookie-consent">
          {
            showBanner && <CookieBanner onDecline={()=>onDecline()} onAllow={()=>onAllow()} translation={props.translation}></CookieBanner>
          }
        </section>

}

export default CookieConsent
